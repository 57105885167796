* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body, html {
    height: 100%;
}

.wrapper {
    display: flex;
    flex-direction: column;
    min-height: 95vh; /* This ensures the wrapper takes at least full height of the viewport */
}

body {
   
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    /*background-image: url("../images/bg.jpg");*/
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: #fdb937;
    background-color: #55449b;
    padding-bottom: 75px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    
}


code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@media (min-width: 768px) {
    body {
        padding-bottom: 110px;
    }
}

.navbar-brand {
    margin-right: 0;
}

    .navbar-brand img {
        height: 150px;
    }

.navbar-dark {
    padding-top: 35px;
    padding-bottom: 35px;
}

    .navbar-dark .navbar-text {
        color: #fdb937;
        opacity: 1;
        padding: 0 20px;
        /* border-right: 1px solid #ffffff;*/
        font-size: 18px;
        font-weight: bold;
        text-align: center;
    }

        .navbar-dark .navbar-text:last-child {
            border-right: 0;
        }

@media (max-width: 767px) {
    .navbar-dark {
        padding-bottom: 0;
    }
}

.page-title {
    font-size: 80px;
    font-weight: bold;
   /* margin-bottom: 6px;*/
}

@media (max-width: 767px) {
    .page-title {
        font-size: 35px;
        /* margin-bottom: 14px;*/
    }
}

.page-description {
    max-width: 465px;
    font-size: 18px;
    /*margin-bottom: 59px;*/
}

@media (max-width: 767px) {
    .page-description {
        font-size: 14px;
    }
}

p {
    font-size: 14px;
    /* margin-bottom: 21px;*/
}

.footer-social-links .social-link {
    display: inline-block;
    text-align: center;
    line-height: 40px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #ffffff;
    color: #fdb937;
    margin-right: 16px;
    transition: all 0.3s ease-in-out;
}

    .footer-social-links .social-link:last-child {
        margin-right: 0;
    }

    .footer-social-links .social-link:hover {
        text-decoration: none;
        background-color: #000000;
        color: #ffffff;
    }
